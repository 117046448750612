<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Акции</div>
                <div class="page__desc">Список акций</div>
            </div>
            <div class="page__actions">
                <router-link
                        :to="{ name: 'CatalogPromotionsCreate' }">
                    <button type="button" class="btn btn-success">Добавить</button>
                </router-link>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Название</th>
                        <th></th>
                        <th>Действие</th>
                    </tr>
                    <draggable v-model="promotions" draggable="tr" tag="tbody">
                        <tr v-for="(promotion, index) in promotions"
                            v-bind:key="promotion.id"
                            style="cursor: move;">
                            <td>{{ promotion.title }}</td>
                            <td>{{ promotion.time_from | moment("DD MMM YYYY HH:mm") }} – {{ promotion.time_to | moment("DD MMM YYYY HH:mm") }}</td>
                            <td>
                                <router-link
                                        :to="{ name: 'CatalogPromotionsUpdate', params: {id: promotion.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        value="Изменить"/>
                                <input
                                        @click="deletePromotion(index)"
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        value="Удалить">
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import draggable from 'vuedraggable'
    import * as types from '@/store/mutation-types'

    export default {
        name: 'CatalogPromotions',
        components: {
            draggable,
        },
        computed: {
            promotions: {
                get() {
                    return this.$store.state.promotions.promotions
                },
                set(value) {
                    let data = [];
                    for (let i=0; i<value.length; i++) {
                        data.push({id: value[i].id, sort: i});
                    }
                    this.sortPromotions(data);

                    this.$store.commit(types.FILL_CATALOG_PROMOTIONS, value)
                }
            }
        },
        methods: {
            ...mapActions([
                'catalogPromotions',
                'catalogPromotionsSort',
                'catalogPromotionsDelete'
            ]),
            async getPromotions() {
                await this.catalogPromotions()
            },
            async sortPromotions(data) {
                await this.catalogPromotionsSort({
                    data: data,
                })
            },
            async deletePromotion(index) {
                if (confirm('Вы уверены, что хотите удалить?')) {
                    let promotion = this.promotions.splice(index, 1)[0];

                    await this.catalogPromotionsDelete({
                        id: promotion.id
                    })
                }
            }
        },
        created() {
            this.getPromotions();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>